import cn from 'classnames';

export enum MarketListFilter {
    'All' = 0,
    'FX' = 1,
    'Crypto' = 2,
    'Index and Comm CFDs' = 3
}

interface TabFiltersMainProps {
    children: React.ReactNode;
}

interface TabFiltersFilterProps {
    filter: {
        label: any;
        value: any;
    };
    selectedFilter: MarketListFilter;
    setSelectedFilter: (value: MarketListFilter) => void;
}

const TabFilters = {
    Main: ({ children }: TabFiltersMainProps) => {
        return <div className="flex min-h-fit overflow-x-auto">{children}</div>;
    },
    Filter: ({ filter, selectedFilter, setSelectedFilter }: TabFiltersFilterProps) => {
        return (
            <button
                key={filter.label}
                className={cn(
                    'p-1 px-2 text-sm font-medium leading-none h-[29px] text-neutral-200 focus:outline-none whitespace-nowrap',
                    {
                        'border-b-[1px] bg-brand-background-dark border-b-brand-border':
                            selectedFilter === filter.value,
                        'text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200': selectedFilter !== filter.value
                    }
                )}
                onClick={() => setSelectedFilter(filter.value)}>
                {filter.label}
            </button>
        );
    }
};

export default TabFilters;
