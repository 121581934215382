/* eslint-disable */
import Long from 'long';
import { CallContext, CallOptions } from 'nice-grpc-common';
import _m0 from 'protobufjs/minimal';
import { Empty } from '../../../../../google/protobuf/empty';
import { ExecutionType } from '../enums/ExecutionTypeProto';
import { EndOfDayNotification } from '../execution/DownstreamExecutionProto';
import {
    CreateOrderRequestRejectDownstreamEvent,
    FutureOrderSnapshotDownstreamEvent,
    FxOrderSnapshotDownstreamEvent,
    PassiveOrderMigrationStatusDownstreamEvent
} from '../order/DownstreamOrderProto';
import {
    AmendOrderRequest,
    CancelOrderRequest,
    CreateFutureOrderRequest,
    CreateFxListOrder,
    CreateFxOrderRequest,
    CreateFxSspOrder,
    FindAllAssignedOrderSnapshots,
    FindAllOrderSnapshotsByOrderId,
    PassiveOrderMigrationRequest
} from '../order/UpstreamOrderProto';
import { OrderSnapshotNotificationDownstreamEvent } from './DownstreamOrderSnapshotNotificationProto';
import { FindOrderSnapshotNotificationsBySessionKeyRequest } from './UpstreamOrderSnapshotNotificationProto';

export const protobufPackage = 'com.celertech.orderrouting.api.order';

export interface OrderSnapshotNotificationGroupsCollection {
    orderSnapshotNotificationGroup: string[];
}

export interface OrderSnapshotNotificationsCollection {
    orderSnapshotNotification: OrderSnapshotNotificationDownstreamEvent[];
}

/** Generic wrapper of the price events. This will allow for sub types to be sent in a single wrapper object. */
export interface OrderRoutingOrderEvent {
    fxOrderSnapshotDownstreamEvent?: FxOrderSnapshotDownstreamEvent | undefined;
    futureOrderSnapshotDownstreamEvent?: FutureOrderSnapshotDownstreamEvent | undefined;
    createOrderRequestRejectDownstreamEvent?: CreateOrderRequestRejectDownstreamEvent | undefined;
    serverAccepted: boolean;
    isHeartBeat: boolean;
}

export interface OrderRoutingOrderEventList {
    orders: OrderRoutingOrderEvent[];
}

export interface HistoricOrderSearchRequest {
    startDate: string;
    endDate: string;
    assetType: string;
    executionType: ExecutionType[];
}

function createBaseOrderSnapshotNotificationGroupsCollection(): OrderSnapshotNotificationGroupsCollection {
    return { orderSnapshotNotificationGroup: [] };
}

export const OrderSnapshotNotificationGroupsCollection = {
    encode(message: OrderSnapshotNotificationGroupsCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.orderSnapshotNotificationGroup) {
            writer.uint32(10).string(v!);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderSnapshotNotificationGroupsCollection {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderSnapshotNotificationGroupsCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderSnapshotNotificationGroup.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<OrderSnapshotNotificationGroupsCollection>
    ): OrderSnapshotNotificationGroupsCollection {
        const message = createBaseOrderSnapshotNotificationGroupsCollection();
        message.orderSnapshotNotificationGroup = object.orderSnapshotNotificationGroup?.map((e) => e) || [];
        return message;
    }
};

function createBaseOrderSnapshotNotificationsCollection(): OrderSnapshotNotificationsCollection {
    return { orderSnapshotNotification: [] };
}

export const OrderSnapshotNotificationsCollection = {
    encode(message: OrderSnapshotNotificationsCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.orderSnapshotNotification) {
            OrderSnapshotNotificationDownstreamEvent.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderSnapshotNotificationsCollection {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderSnapshotNotificationsCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderSnapshotNotification.push(
                        OrderSnapshotNotificationDownstreamEvent.decode(reader, reader.uint32())
                    );
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderSnapshotNotificationsCollection>): OrderSnapshotNotificationsCollection {
        const message = createBaseOrderSnapshotNotificationsCollection();
        message.orderSnapshotNotification =
            object.orderSnapshotNotification?.map((e) => OrderSnapshotNotificationDownstreamEvent.fromPartial(e)) || [];
        return message;
    }
};

function createBaseOrderRoutingOrderEvent(): OrderRoutingOrderEvent {
    return {
        fxOrderSnapshotDownstreamEvent: undefined,
        futureOrderSnapshotDownstreamEvent: undefined,
        createOrderRequestRejectDownstreamEvent: undefined,
        serverAccepted: false,
        isHeartBeat: false
    };
}

export const OrderRoutingOrderEvent = {
    encode(message: OrderRoutingOrderEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.fxOrderSnapshotDownstreamEvent !== undefined) {
            FxOrderSnapshotDownstreamEvent.encode(
                message.fxOrderSnapshotDownstreamEvent,
                writer.uint32(10).fork()
            ).ldelim();
        }
        if (message.futureOrderSnapshotDownstreamEvent !== undefined) {
            FutureOrderSnapshotDownstreamEvent.encode(
                message.futureOrderSnapshotDownstreamEvent,
                writer.uint32(18).fork()
            ).ldelim();
        }
        if (message.createOrderRequestRejectDownstreamEvent !== undefined) {
            CreateOrderRequestRejectDownstreamEvent.encode(
                message.createOrderRequestRejectDownstreamEvent,
                writer.uint32(26).fork()
            ).ldelim();
        }
        if (message.serverAccepted === true) {
            writer.uint32(32).bool(message.serverAccepted);
        }
        if (message.isHeartBeat === true) {
            writer.uint32(40).bool(message.isHeartBeat);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderRoutingOrderEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderRoutingOrderEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.fxOrderSnapshotDownstreamEvent = FxOrderSnapshotDownstreamEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 2:
                    message.futureOrderSnapshotDownstreamEvent = FutureOrderSnapshotDownstreamEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 3:
                    message.createOrderRequestRejectDownstreamEvent = CreateOrderRequestRejectDownstreamEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 4:
                    message.serverAccepted = reader.bool();
                    break;
                case 5:
                    message.isHeartBeat = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderRoutingOrderEvent>): OrderRoutingOrderEvent {
        const message = createBaseOrderRoutingOrderEvent();
        message.fxOrderSnapshotDownstreamEvent =
            object.fxOrderSnapshotDownstreamEvent !== undefined && object.fxOrderSnapshotDownstreamEvent !== null
                ? FxOrderSnapshotDownstreamEvent.fromPartial(object.fxOrderSnapshotDownstreamEvent)
                : undefined;
        message.futureOrderSnapshotDownstreamEvent =
            object.futureOrderSnapshotDownstreamEvent !== undefined &&
            object.futureOrderSnapshotDownstreamEvent !== null
                ? FutureOrderSnapshotDownstreamEvent.fromPartial(object.futureOrderSnapshotDownstreamEvent)
                : undefined;
        message.createOrderRequestRejectDownstreamEvent =
            object.createOrderRequestRejectDownstreamEvent !== undefined &&
            object.createOrderRequestRejectDownstreamEvent !== null
                ? CreateOrderRequestRejectDownstreamEvent.fromPartial(object.createOrderRequestRejectDownstreamEvent)
                : undefined;
        message.serverAccepted = object.serverAccepted ?? false;
        message.isHeartBeat = object.isHeartBeat ?? false;
        return message;
    }
};

function createBaseOrderRoutingOrderEventList(): OrderRoutingOrderEventList {
    return { orders: [] };
}

export const OrderRoutingOrderEventList = {
    encode(message: OrderRoutingOrderEventList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.orders) {
            OrderRoutingOrderEvent.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderRoutingOrderEventList {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderRoutingOrderEventList();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orders.push(OrderRoutingOrderEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderRoutingOrderEventList>): OrderRoutingOrderEventList {
        const message = createBaseOrderRoutingOrderEventList();
        message.orders = object.orders?.map((e) => OrderRoutingOrderEvent.fromPartial(e)) || [];
        return message;
    }
};

function createBaseHistoricOrderSearchRequest(): HistoricOrderSearchRequest {
    return { startDate: '0', endDate: '0', assetType: '', executionType: [] };
}

export const HistoricOrderSearchRequest = {
    encode(message: HistoricOrderSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.startDate !== '0') {
            writer.uint32(8).int64(message.startDate);
        }
        if (message.endDate !== '0') {
            writer.uint32(16).int64(message.endDate);
        }
        if (message.assetType !== '') {
            writer.uint32(26).string(message.assetType);
        }
        writer.uint32(34).fork();
        for (const v of message.executionType) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): HistoricOrderSearchRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHistoricOrderSearchRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.startDate = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.endDate = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.assetType = reader.string();
                    break;
                case 4:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.executionType.push(reader.int32() as any);
                        }
                    } else {
                        message.executionType.push(reader.int32() as any);
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<HistoricOrderSearchRequest>): HistoricOrderSearchRequest {
        const message = createBaseHistoricOrderSearchRequest();
        message.startDate = object.startDate ?? '0';
        message.endDate = object.endDate ?? '0';
        message.assetType = object.assetType ?? '';
        message.executionType = object.executionType?.map((e) => e) || [];
        return message;
    }
};

export type OrderServiceDefinition = typeof OrderServiceDefinition;
export const OrderServiceDefinition = {
    name: 'OrderService',
    fullName: 'com.celertech.orderrouting.api.order.OrderService',
    methods: {
        findOrderSnapshotNotificationsBySessionKeyRequest: {
            name: 'findOrderSnapshotNotificationsBySessionKeyRequest',
            requestType: FindOrderSnapshotNotificationsBySessionKeyRequest,
            requestStream: false,
            responseType: OrderSnapshotNotificationsCollection,
            responseStream: false,
            options: {}
        },
        findAllFuturesOrders: {
            name: 'findAllFuturesOrders',
            requestType: FindAllAssignedOrderSnapshots,
            requestStream: false,
            responseType: OrderRoutingOrderEventList,
            responseStream: false,
            options: {}
        },
        findAllFxOrders: {
            name: 'findAllFxOrders',
            requestType: FindAllAssignedOrderSnapshots,
            requestStream: false,
            responseType: OrderRoutingOrderEventList,
            responseStream: false,
            options: {}
        },
        updateOnAllFxOrderSnapshots: {
            name: 'updateOnAllFxOrderSnapshots',
            requestType: Empty,
            requestStream: false,
            responseType: OrderRoutingOrderEvent,
            responseStream: true,
            options: {}
        },
        upateOnAllFuturesOrderSnapshots: {
            name: 'upateOnAllFuturesOrderSnapshots',
            requestType: Empty,
            requestStream: false,
            responseType: OrderRoutingOrderEvent,
            responseStream: true,
            options: {}
        },
        createFuturesOrderRequest: {
            name: 'createFuturesOrderRequest',
            requestType: CreateFutureOrderRequest,
            requestStream: false,
            responseType: OrderRoutingOrderEvent,
            responseStream: false,
            options: {}
        },
        createFxOrderRequest: {
            name: 'createFxOrderRequest',
            requestType: CreateFxOrderRequest,
            requestStream: false,
            responseType: OrderRoutingOrderEvent,
            responseStream: false,
            options: {}
        },
        createStrategyOrderRequest: {
            name: 'createStrategyOrderRequest',
            requestType: CreateFxListOrder,
            requestStream: false,
            responseType: OrderRoutingOrderEvent,
            responseStream: false,
            options: {}
        },
        cancelOrderRequest: {
            name: 'cancelOrderRequest',
            requestType: CancelOrderRequest,
            requestStream: false,
            responseType: Empty,
            responseStream: false,
            options: {}
        },
        amendOrderRequest: {
            name: 'amendOrderRequest',
            requestType: AmendOrderRequest,
            requestStream: false,
            responseType: Empty,
            responseStream: false,
            options: {}
        },
        findAllOrderSnapshotsByOrderId: {
            name: 'findAllOrderSnapshotsByOrderId',
            requestType: FindAllOrderSnapshotsByOrderId,
            requestStream: false,
            responseType: OrderRoutingOrderEventList,
            responseStream: false,
            options: {}
        },
        findAllOrdersBySearch: {
            name: 'findAllOrdersBySearch',
            requestType: HistoricOrderSearchRequest,
            requestStream: false,
            responseType: OrderRoutingOrderEventList,
            responseStream: false,
            options: {}
        },
        registerEodEvents: {
            name: 'registerEodEvents',
            requestType: Empty,
            requestStream: false,
            responseType: EndOfDayNotification,
            responseStream: true,
            options: {}
        },
        createFxSspOrder: {
            name: 'createFxSspOrder',
            requestType: CreateFxSspOrder,
            requestStream: false,
            responseType: OrderRoutingOrderEvent,
            responseStream: false,
            options: {}
        },
        createPassiveOrderMigrationRequest: {
            name: 'createPassiveOrderMigrationRequest',
            requestType: PassiveOrderMigrationRequest,
            requestStream: false,
            responseType: PassiveOrderMigrationStatusDownstreamEvent,
            responseStream: false,
            options: {}
        }
    }
} as const;

export interface OrderServiceServiceImplementation<CallContextExt = {}> {
    findOrderSnapshotNotificationsBySessionKeyRequest(
        request: FindOrderSnapshotNotificationsBySessionKeyRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderSnapshotNotificationsCollection>>;
    findAllFuturesOrders(
        request: FindAllAssignedOrderSnapshots,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderRoutingOrderEventList>>;
    findAllFxOrders(
        request: FindAllAssignedOrderSnapshots,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderRoutingOrderEventList>>;
    updateOnAllFxOrderSnapshots(
        request: Empty,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<OrderRoutingOrderEvent>>;
    upateOnAllFuturesOrderSnapshots(
        request: Empty,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<OrderRoutingOrderEvent>>;
    createFuturesOrderRequest(
        request: CreateFutureOrderRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderRoutingOrderEvent>>;
    createFxOrderRequest(
        request: CreateFxOrderRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderRoutingOrderEvent>>;
    createStrategyOrderRequest(
        request: CreateFxListOrder,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderRoutingOrderEvent>>;
    cancelOrderRequest(request: CancelOrderRequest, context: CallContext & CallContextExt): Promise<DeepPartial<Empty>>;
    amendOrderRequest(request: AmendOrderRequest, context: CallContext & CallContextExt): Promise<DeepPartial<Empty>>;
    findAllOrderSnapshotsByOrderId(
        request: FindAllOrderSnapshotsByOrderId,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderRoutingOrderEventList>>;
    findAllOrdersBySearch(
        request: HistoricOrderSearchRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderRoutingOrderEventList>>;
    registerEodEvents(
        request: Empty,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<EndOfDayNotification>>;
    createFxSspOrder(
        request: CreateFxSspOrder,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<OrderRoutingOrderEvent>>;
    createPassiveOrderMigrationRequest(
        request: PassiveOrderMigrationRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<PassiveOrderMigrationStatusDownstreamEvent>>;
}

export interface OrderServiceClient<CallOptionsExt = {}> {
    findOrderSnapshotNotificationsBySessionKeyRequest(
        request: DeepPartial<FindOrderSnapshotNotificationsBySessionKeyRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderSnapshotNotificationsCollection>;
    findAllFuturesOrders(
        request: DeepPartial<FindAllAssignedOrderSnapshots>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderRoutingOrderEventList>;
    findAllFxOrders(
        request: DeepPartial<FindAllAssignedOrderSnapshots>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderRoutingOrderEventList>;
    updateOnAllFxOrderSnapshots(
        request: DeepPartial<Empty>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<OrderRoutingOrderEvent>;
    upateOnAllFuturesOrderSnapshots(
        request: DeepPartial<Empty>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<OrderRoutingOrderEvent>;
    createFuturesOrderRequest(
        request: DeepPartial<CreateFutureOrderRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderRoutingOrderEvent>;
    createFxOrderRequest(
        request: DeepPartial<CreateFxOrderRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderRoutingOrderEvent>;
    createStrategyOrderRequest(
        request: DeepPartial<CreateFxListOrder>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderRoutingOrderEvent>;
    cancelOrderRequest(
        request: DeepPartial<CancelOrderRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<Empty>;
    amendOrderRequest(request: DeepPartial<AmendOrderRequest>, options?: CallOptions & CallOptionsExt): Promise<Empty>;
    findAllOrderSnapshotsByOrderId(
        request: DeepPartial<FindAllOrderSnapshotsByOrderId>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderRoutingOrderEventList>;
    findAllOrdersBySearch(
        request: DeepPartial<HistoricOrderSearchRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderRoutingOrderEventList>;
    registerEodEvents(
        request: DeepPartial<Empty>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<EndOfDayNotification>;
    createFxSspOrder(
        request: DeepPartial<CreateFxSspOrder>,
        options?: CallOptions & CallOptionsExt
    ): Promise<OrderRoutingOrderEvent>;
    createPassiveOrderMigrationRequest(
        request: DeepPartial<PassiveOrderMigrationRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<PassiveOrderMigrationStatusDownstreamEvent>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}

export type ServerStreamingMethodResult<Response> = {
    [Symbol.asyncIterator](): AsyncIterator<Response, void>;
};
