import { parseBlotterOrder, parseBlotterTrade } from '@/helpers/blotterHelper';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrderStatus } from '../../compiled_proto/com/celertech/orderrouting/api/enums/OrderStatusProto';
import { FxOrderSnapshotDownstreamEvent } from '../../compiled_proto/com/celertech/orderrouting/api/order/DownstreamOrderProto';
import { BlotterItem, BlotterTrade } from '../../model/blotters';
import { getBlotterOrders, getBlotterTrades } from '../../services/OrderService';
import { RootState } from '../store';

export const getBlotterItemsThunk = createAsyncThunk('blotter/getOrders', async (_, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        if (state.auth.user) return await getBlotterOrders(state.auth.user);

        return [];
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const getBlotterTradesThunk = createAsyncThunk('blotter/getTrades', async (_, { getState, rejectWithValue }) => {
    try {
        const state = getState() as RootState;
        if (state.auth.user) return await getBlotterTrades(state.auth.user, state.blotter.blotterOrders);

        return [];
    } catch (err) {
        return rejectWithValue(err);
    }
});

export interface BlotterState {
    blotterOrders: BlotterItem[];
    blotterTrades: BlotterTrade[];
    historicOrders: BlotterItem[] | null;
    historicTrades: BlotterTrade[] | null;
}

const initialState: BlotterState = {
    blotterOrders: [],
    blotterTrades: [],
    historicOrders: null,
    historicTrades: null
};

export const blotterSlice = createSlice({
    name: 'blotter',
    initialState,
    reducers: {
        setHistoricOrders: (state, action: PayloadAction<BlotterItem[] | null>) => {
            state.historicOrders = action.payload;
        },
        setHistoricTrades: (state, action: PayloadAction<BlotterTrade[] | null>) => {
            state.historicTrades = action.payload;
        },
        processOrderEvent: (state, action: PayloadAction<FxOrderSnapshotDownstreamEvent>) => {
            const orderUpdate = action.payload;

            // Process for order blotter
            const order = parseBlotterOrder(orderUpdate);
            const exists = state.blotterOrders.findIndex((o) => o.orderId === order.orderId);

            if (exists === -1) {
                state.blotterOrders.push(order);
            } else {
                state.blotterOrders[exists] = order;
            }

            // Process for trades blotter
            if (orderUpdate.orderStatus == OrderStatus.FILLED || orderUpdate.orderStatus == OrderStatus.PART_FILLED) {
                state.blotterTrades.push(parseBlotterTrade(orderUpdate));

                const fillCounter: Record<string, number> = {};
                state.blotterTrades.map((item) => {
                    if (fillCounter[item.orderId]) {
                        fillCounter[item.orderId] += 1;
                    } else {
                        fillCounter[item.orderId] = 1;
                    }

                    item.fillNo = fillCounter[item.orderId];
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBlotterItemsThunk.pending, (state) => {})
            .addCase(getBlotterItemsThunk.fulfilled, (state, action) => {
                state.blotterOrders = action.payload;
            })
            .addCase(getBlotterItemsThunk.rejected, (state) => {});

        builder
            .addCase(getBlotterTradesThunk.pending, (state) => {})
            .addCase(getBlotterTradesThunk.fulfilled, (state, action) => {
                state.blotterTrades = action.payload;
            })
            .addCase(getBlotterTradesThunk.rejected, (state) => {});
    }
});
export const { setHistoricOrders, setHistoricTrades, processOrderEvent } = blotterSlice.actions;

export const selectBlotterItems = (state: RootState) => state.blotter.blotterOrders;
export const selectBlotterTrades = (state: RootState) => state.blotter.blotterTrades;
export const selectHistoricTrades = (state: RootState) => state.blotter.historicTrades;
export const selectHistoricOrders = (state: RootState) => state.blotter.historicOrders;

export default blotterSlice.reducer;
