import { OrderHistory } from '@/model/blotters';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import cn from 'classnames';
import { orderStatusColorCell } from './orderBlotterTableHelper';

export const historyColumns = (generalSettings): ColumnDef<OrderHistory>[] => {
    return [
        { accessorKey: 'time', header: `Time (${generalSettings.timezone.value})`, size: 150 },
        { accessorKey: 'pair', header: 'Pair', size: 100 },
        { accessorKey: 'ccy', header: 'Ccy', size: 60 },
        { accessorKey: 'orderType', header: 'Order Type', size: 100 },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 120,
            cell: (context: CellContext<OrderHistory, any>) => {
                const { getValue } = context;
                return <span className={orderStatusColorCell(context)}>{getValue()}</span>;
            }
        },
        {
            accessorKey: 'executionType',
            header: 'Execution Type',
            size: 120,
            cell: (context: CellContext<OrderHistory, any>) => {
                const { getValue } = context;
                return <span className={orderStatusColorCell(context)}>{getValue()}</span>;
            }
        },
        {
            accessorKey: 'side',
            header: 'Side',
            size: 60,
            cell: ({ row, getValue }: CellContext<OrderHistory, any>) => {
                const value = getValue();
                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value === 'BUY',
                            'text-brand-red': value === 'SELL'
                        })}>
                        {value}
                    </div>
                );
            }
        },
        { accessorKey: 'qty', header: 'Qty', size: 80 },
        { accessorKey: 'price', header: 'Price', size: 80 },
        { accessorKey: 'lastPrice', header: 'Last Price', size: 80 },
        { accessorKey: 'lastQty', header: 'Last Qty', size: 80 },
        { accessorKey: 'avgPrice', header: 'Avg Price', size: 80 },
        { accessorKey: 'stopPrice', header: 'Stop Price', size: 80 },
        { accessorKey: 'cumQty', header: 'Cum Qty', size: 80 },
        { accessorKey: 'leavesQty', header: 'Leaves Qty', size: 90 },
        { accessorKey: 'tif', header: 'Tif', size: 60 },
        { accessorKey: 'account', header: 'Account', size: 100 },
        { accessorKey: 'user', header: 'User', size: 100 }
    ];
};
