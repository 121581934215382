import instrumentConfig from '@/config/instruments';
import { PairMap } from '@/state/reducers/marketPairSlice';
import { useClickOutside } from '@/utils/hooks/useClickOutside';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { useSso } from '@/utils/providers/SSOProvider';
import { autoUpdate, flip, offset, Placement, size, useFloating } from '@floating-ui/react';
import cn from 'classnames';
import { useCallback, useMemo } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import Drawer from './Drawer';

export const formConfig = {
    offsetOverride: { mainAxis: 2, crossAxis: -151 }
};

interface InstrumentInfoProps {
    market?: PairMap;
    placement?: Placement;
    width?: string;
    offsetOverride?: { mainAxis: number; crossAxis: number };
    hasBg?: boolean;
}

const InstrumentInfo = ({ market, placement, width, offsetOverride, hasBg }: InstrumentInfoProps) => {
    const { jwt, instrumentsApi } = useSso();
    const { data: instruments } = instrumentsApi;

    const isXplorSpot = !location.pathname.includes('/trader');

    const showInstrumentInfo = useDisclosure(false);

    const onClickOutside = useCallback(() => {
        showInstrumentInfo[1].close();
    }, [showInstrumentInfo]);

    const clickOutsideRef = useClickOutside(onClickOutside);
    const [ccy1, ccy2] = useMemo(() => market?.celer.split('/') || [], [market?.celer]);

    const ccy1Config = useCurrency(ccy1);
    const ccy2Config = useCurrency(ccy2);

    const xplorSpotConfig = useMemo(() => instrumentConfig[market?.celer || ''] || {}, [market]);
    const ohoConfig = useMemo(
        () =>
            instruments?.data?.find((instrument) => {
                if (isXplorSpot) {
                    if (['Index'].includes(xplorSpotConfig.type)) {
                        return instrument.name === `CFD/${market?.celer}`;
                    } else {
                        return instrument.name.replace('CFD/') === market?.celer;
                    }
                } else {
                    if (['Index', 'Crypto'].includes(xplorSpotConfig.type)) {
                        return instrument.name === `CFD/${market?.celer}`;
                    } else {
                        return instrument.name.replace('CFD/') === market?.celer;
                    }
                }
            }),
        [xplorSpotConfig, instruments, market]
    );

    const { isCFD, isRollingFiatFX } = useMemo(
        () => ({
            isCFD: ohoConfig?.type === 'CFD',
            isRollingFiatFX: ohoConfig?.type === 'FX' && ohoConfig?.subType === 'Spot',
            isCryptoSpot: ohoConfig?.type === 'Crypto' && ohoConfig?.subType === 'Spot'
        }),
        [ohoConfig]
    );

    const middleware = useMemo(() => {
        return [
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, { width });
                }
            }),
            offset(offsetOverride || { mainAxis: 0, crossAxis: -2 }),
            flip()
        ];
    }, []);

    const { refs, floatingStyles } = useFloating({
        strategy: 'fixed',
        placement: placement || 'bottom-start',
        whileElementsMounted: autoUpdate,
        middleware
    });

    return (
        <div ref={clickOutsideRef}>
            <div ref={refs.setReference} onDrag={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
                <div
                    title="Instrument Info"
                    className={cn('h-full flex items-center p-1 cursor-pointer', {
                        ['hover:text-brand-primary-light']: !hasBg,
                        ['hover:bg-brand-primary']: hasBg
                    })}
                    onClick={showInstrumentInfo[1].toggle}>
                    <MdInfoOutline className="w-3 h-3" />
                </div>
                {showInstrumentInfo[0] && (
                    <div
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className="flex flex-col gap-2 p-2 bg-brand-background border border-neutral-700 z-10 cursor-auto">
                        {jwt && ohoConfig && (
                            <div className="flex flex-wrap gap-3 justify-between text-xs">
                                <Drawer.InfoSubGroup
                                    details={{
                                        Symbol: { value: market?.show || '-' },
                                        Type: { value: ohoConfig.type },
                                        Underlying: { value: ohoConfig.underlying, hidden: !isCFD },
                                        'Ccy 1': { value: ohoConfig.currency1, hidden: isCFD },
                                        CFI: { value: ohoConfig.cfi || '-', hidden: !isCFD && !isRollingFiatFX }
                                    }}
                                />
                                <Drawer.InfoSubGroup
                                    className="flex-[3_1_0]"
                                    details={{
                                        Description: { value: ohoConfig.description || '-' },
                                        'Sub Type': { value: ohoConfig.subType },
                                        Ccy: { value: ohoConfig.currency2, hidden: !isCFD },
                                        'Ccy 2': { value: ohoConfig.currency2, hidden: isCFD },
                                        UPI: { value: ohoConfig.upi || '-', hidden: !isCFD && !isRollingFiatFX },
                                        'MiFID Scope': {
                                            value: ohoConfig.miFIDscope ? 'Enabled' : 'Disabled',
                                            hidden: !isCFD
                                        }
                                    }}
                                />
                            </div>
                        )}
                        {(!jwt || !ohoConfig) && (
                            <div className="flex flex-wrap gap-3 justify-between text-xs">
                                <Drawer.InfoSubGroup
                                    labelClassName="whitespace-nowrap"
                                    details={{
                                        Type: { value: xplorSpotConfig.type },
                                        'Price Decimals': { value: xplorSpotConfig.price_decimals }
                                    }}
                                />
                                <Drawer.InfoSubGroup
                                    labelClassName="whitespace-nowrap"
                                    className="flex-[2_1_0]"
                                    details={{
                                        'Ccy 2 Enabled': { value: xplorSpotConfig.ccy2_enabled ? 'Yes' : 'No' },
                                        'Min Order Size': {
                                            value: `${ccy1Config.show} ${ccy1Config.min_order_size}`,
                                            hidden:
                                                !!xplorSpotConfig.ccy2_enabled ||
                                                ['Index', 'Commodity'].includes(xplorSpotConfig.type)
                                        },
                                        'Min Order Size (Ccy1)': {
                                            value: `${ccy1Config.show} ${ccy1Config.min_order_size}`,
                                            hidden: !xplorSpotConfig.ccy2_enabled
                                        },
                                        'Min Order Size (Ccy2)': {
                                            value: `${ccy2Config.show} ${ccy2Config.min_order_size}`,
                                            hidden: !xplorSpotConfig.ccy2_enabled
                                        }
                                    }}
                                />
                            </div>
                        )}
                        {/* <Fragment>
                            <Entry label="Type">{xplorSpotConfig.type}</Entry>
                            <Entry label="Pip Size">{xplorSpotConfig.pip_size}</Entry>
                            <Entry label="Price Decimals">{xplorSpotConfig.price_decimals}</Entry>
                            <Entry label="Ccy 2 Enabled">{xplorSpotConfig.ccy2_enabled}</Entry>
                            <Entry label="Netdania Name">{xplorSpotConfig.netdania}</Entry>
                            <Entry label="Netdania Provider">{xplorSpotConfig.provider}</Entry>
                        </Fragment> */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default InstrumentInfo;
